.home-workers {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 80%;
  width: min(800px, 80%);
  justify-content: flex-start;
  margin-inline: auto;
  margin-bottom: 150px;
  /*   border-inline: var(--primary-colour) 5px solid;
 */
  padding-inline: 1.5rem;
}

.home-workers h1,
.home-workers span {
  color: var(--primary-colour);
  font-weight: bold;
}

.maja-image {
  width: min(80%, 400px);
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  margin: auto;
  filter: drop-shadow(10px 10px 30px grey);
  margin-block: 50px;
  object-fit: cover;
}

.work-overlay {
  width: 100%;
  position: relative;
  height: 625px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  margin-top: 70px;
  box-sizing: border-box;
}

.work-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

.work-overlay p {
  position: absolute;
  bottom: -610px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding-left: 8px;
  padding-top: 5px;
  overflow: hidden;
}
