.about-us {
  width: 100%;
}

.about-intro {
  height: 100vh;
  background-image: url("../../files/exercisekit.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-color: grey;
  background-blend-mode: multiply;
}

.about-intro img {
  position: absolute;
  bottom: 5%;
  left: 5%;
  border-radius: 10px;
  height: 120px;
  border: 1.5px solid white;
}

.about-intro h1 {
  position: absolute;
  text-align: center;
  width: 100%;
  filter: drop-shadow(0 0 80px black);
  color: white;
  text-shadow: 0 0 10px black;
  box-sizing: border-box;

  padding-inline: 10%;
  top: 40%;
  font-size: clamp(2rem, 5vw, 3.5rem);
}

.description-image {
    width: min(550px, 80%);
    margin-bottom: 120px;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
}


.pdf-organise {
    width: min(700px, 80%);
    display: flex;
    background-image: linear-gradient(56deg, var(--primary-colour) 50%, rgb(50, 50, 50) 50%);
margin-inline: auto;
justify-content: space-around;
padding: 2rem;
margin-bottom: 120px;
border-radius: 10px;
box-shadow: 5px 5px 20px grey;
}

.pdf-organise a {
    text-decoration: none;
    color: white;
    max-width: 42.5%;
    text-align: center;
    font-weight: bold;
}

.link-back {
    display: flex;
    justify-content: center;
    margin-inline: auto;
    background-color: rgb(212, 65, 65);
    color: white;
    text-decoration: none;
    padding: 1rem 3rem;
    text-align: center;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 80px;
}

.link-back:hover {
    opacity: 0.75;
}