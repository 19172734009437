.pain-free {
  width: 100%;
  overflow: hidden;
}

.serv-container {
  margin-block: 80px;
  padding-inline: 10%;
  width: min(700px, 80%);
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-left: solid 8px rgb(212, 65, 65);
}

.serv-container h1 {
  color: var(--primary-colour);
  font-size: clamp(2.5rem, 5vw, 3.75rem);
}

.serv-container img {
  filter: drop-shadow(0 0 10px grey);
}

.serv-container ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 0;
  margin-block: 40px;
  padding: 0;
  line-height: 1.5;
}

.serv-container ul li {
  border-bottom: solid 3px rgb(225, 225, 225);
  padding-bottom: 1.5rem;
}

.serv-container ul li::first-line {
  font-weight: bold;
  letter-spacing: 2px;
  color: var(--primary-colour);
  text-shadow: 1px 1px 10px gray;
}

.serv-icon {
  color: var(--primary-colour);
}

.serv-container a {
  text-decoration: none;
  color: white;
  background-color: rgb(212, 65, 65);
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.serv-container a:nth-of-type(2) {
  background-color: var(--primary-colour);
}

.serv-container a:hover {
  opacity: 0.75;
}
