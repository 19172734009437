:root {
  --black: black;
  --primary-colour: #3baeb3;
  --white-colour: white;
}

.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.85);
  padding-block: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  transition: all 0.8s ease-in;
  height: 60px;
  box-shadow: 0 0 20px silver;
}

.navigation a {
  text-decoration: none;
  color: var(--black);
  transition: all 0.3s ease-in-out;
  font-weight: bold;
}

.navigation a:hover {
  color: var(--primary-colour);
}
