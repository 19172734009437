.first-image {
  height: 100vh;
  background-image: url(../../files/firstimg.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}


.first-image img {
    position: absolute;
    bottom: 5%;
    left: 5%;
    border-radius: 10px;
    height: 120px;
}

.first-image h1, .first-image h3 {
    position: absolute;
    text-align: center;
    width: 100%;
    filter: drop-shadow(0 0 80px black);
    color: white;
    text-shadow: 0 0 10px black;
    box-sizing: border-box;

    padding-inline: 10%;
  
}

.first-image h1 {
    top: 25%;
    font-size: clamp(2rem, 5vw, 3.5rem);
  
}

.first-image h3 {
    top: 60%;
    font-size: clamp(1.2rem, 3vw, 1.8rem);
}