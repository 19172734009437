:root {
    --white: white;
}

.home-about {
    display: flex;
    gap: 2rem;
    width: 80%;
    margin-inline: auto;
    margin-block: 150px;
}

.home-about-first {
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
}

.home-about-first img {
    width: 75%;
    user-select: none;
}

.home-about-first h3 {
    background-color: var(--primary-colour);
    border-radius: 10px;
    color: var(--white);
    padding: 0.5rem
}

.home-about-second {
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;

}

.inner {
    display: flex;
    width: 80%;
}

.inner span {
    font-weight: bold;
    color: var(--primary-colour);
}


@media (max-width: 850px) {
    .home-about {
        flex-direction: column;
    }

    .home-about-first {
        border-bottom: var(--primary-colour) solid 6px;
        padding-bottom: 60px;
        align-items: flex-start;
    }
}