.error {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  text-align: center;
  box-sizing: border-box;
  flex-direction: column;
  overflow-x: hidden;
  overflow-wrap: anywhere;
}

.error h1,
.error h3 {
  padding-inline: 10%;
}

.error a {
  color: rgb(212, 65, 65);
}

.error em {
  color: var(--primary-colour);
  font-weight: bold;
}

.error img {
  width: 100%;
  border-block: rgb(212, 65, 65) solid 10px;
  padding-inline: 0;
  user-select: none;
}

.error a.transport {
  background-color: rgb(212, 65, 65);

  color: white;

  width: 100%;
  height: 40px;
  text-decoration: none;
  font-size: 1.25rem;
  padding-block: 3px;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
