.contact {
  width: 100%;
  overflow: hidden;
}



.contact-intro {
  height: 100vh;
  background-image: url("../../files/younggirl2.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-color: grey;
  background-blend-mode: multiply;
}

.contact-intro img {
  position: absolute;
  bottom: 5%;
  left: 5%;
  border-radius: 10px;
  height: 120px;
  border: 1.5px solid white;
}

.contact-intro h1 {
  position: absolute;
  text-align: center;
  width: 100%;
  filter: drop-shadow(0 0 80px black);
  color: white;
  text-shadow: 0 0 10px black;
  box-sizing: border-box;

  padding-inline: 10%;
  top: 40%;
  font-size: clamp(2rem, 5vw, 3.5rem);
}

.contact-containers {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-inline: 10%;
  margin-block: 80px;
}

.contact-containers img {
    user-select: none;
    height: 100%;
    filter: drop-shadow(0 0 10px silver);
    width: 100%;
  }

.contact-container {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
}

.contact-container h1 {
  flex-basis: 50%;
}

.contact-container > :nth-child(2) {
  flex-basis: 50%;
  height: 400px;


}

.cont-map {
    border: 0;
    outline: 0;
    filter: drop-shadow(0 0 10px silver);
}

.work-hour {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 2rem;
}

.work-hour a {
    text-decoration: none;
    color: var(--primary-colour);
    font-size: clamp(1.15rem, 3vw, 1.65rem);
    transition: all 0.3s ease-in-out;
}

.work-hour a:hover {
    color: rgb(50, 50, 50);
}




@media (max-width: 900px) {
    .contact-containers {
        gap: 120px;

    }
  .contact-container {
    flex-direction: column;
  }

  .contact-container h1 {
    flex-basis: 100%;
  }

  .contact-container img {
      width: 100%;
  }

  .contact-container > :nth-child(2) {
    flex-basis: 100%;
  }

  .cont-map {
      width: 100%;
      height: 100%;
  }

  .work-hour {
      padding-left: 0;
  }
}
