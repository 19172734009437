.footer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: rgb(50, 50, 50);
  width: 100%;
  border-top: var(--primary-colour) 8px solid;
  overflow: hidden;
}

.footer-box-1 {
  display: flex;
  align-items: center;
  justify-content: stretch;
  height: 100px;
  width: 100%;
  margin-top: 50px;
  overflow: hidden;
}

.footer-box-1 img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
  user-select: none;
  flex-basis: 100%;
}

.footer-box-2 {
  color: white;
  padding-inline: 10%;
}

.footer-box-3 {
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  padding-inline: 10%;
  color: white;
}

.information {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.information a {
  text-decoration: none;
  color: var(--primary-colour);
  height: fit-content;
  width: fit-content;
  transition: all 0.3s ease-in-out;
}

.information a:hover {
  color: white;
}

.foot-icon {
  transform: scale(1.5);
  margin-right: 30px;
}

.working-time {
  flex: 1;
}

.working-time span {
  color: var(--primary-colour);
}

.fourth-div {
  height: 12px;
  width: 100%;
  background-color: var(--primary-colour);
  margin-bottom: 2px;
}

.fifth-div {
  color: white;
  font-size: 0.8rem;
  margin: auto;
  margin-bottom: 16px;
  padding-inline: 10%;
  text-align: start;
}

.fifth-div a {
  text-decoration: none;
  color: #d82020;
  font-weight: bold;
  background-color: white;
  padding: 0px 3px;
  border-radius: 5px;
}

.sitemap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 10%;
}

.sitemap h3 {
  color: var(--primary-colour);
  font-weight: bold;
}

.sitemap a {
  text-decoration: none;
  color: white;
  width: fit-content;
  transition: all 0.3s ease-in-out;
  transform-origin: left;
}

.sitemap a:hover {
  color: var(--primary-colour);
  transform: scaleX(1.2);
}

@media (max-width: 700px) {
  .footer-box-3 {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
}
