.home-services {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 80%;
  width: min(800px, 80%);
  justify-content: flex-start;
  margin: 200px auto 150px;
  border-inline: var(--primary-colour) 5px solid;
  padding-inline: 1.5rem;
  padding-bottom: 30px;
}

.home-services h1 {
  color: var(--primary-colour);
  font-weight: bold;
}

.home-services ul {
  list-style-type: none;
}

.home-services ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
  font-size: clamp(1.25rem, 3.5vw, 2rem);
  border-bottom: silver solid 3px;
}

.home-services a {
  text-decoration: none;
  background-image: repeating-linear-gradient(
    135deg,
    var(--primary-colour) 30px,
    black 60px
  );
  color: var(--white);
  font-weight: bold;
  width: fit-content;
  padding: 1rem 3.5rem;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.home-services a:hover {
  opacity: 0.75;
}

.hs-icon {
  font-size: 2rem;
  color: var(--primary-colour);
  margin-right: 1.5rem;
}

.hs img {
  display: flex;
  width: min(700px, 90%);
  margin: auto;
  border-radius: 10px;
  filter: drop-shadow(0 0 30px grey);
  margin-bottom: 100px;
  user-select: none;
}

.hs img:nth-of-type(3) {
    margin-bottom: 30px;
}


.download {
    text-decoration: none;
    font-weight: bold;
    font-style: italic;
    color: grey;
    display: flex;
    justify-content: center;
    margin-inline: auto;
    margin-bottom: 150px;
    width: fit-content;
}