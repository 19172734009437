.home-review {
  background-color: var(--primary-colour);
  background-image: url("../../files/girlmassage.jpeg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  height: 500px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 150px;
}

.hw-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: silver;
  height: 100%;
  width: min(80px, 8%);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  padding-inline: 5px;
  transition: all 0.5s ease-in-out;
  z-index: 2;
}

.hw-icon:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.hw-icon-left {
  left: 0%;
}

.hw-icon-right {
  right: 0%;
}

blockquote {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 70%;

  height: fit-content;

  color: white;
  font-size: clamp(0.8rem, 3vw, 1.6rem);
  text-align: center;
  font-weight: bold;
  font-style: oblique;
  user-select: none;
  pointer-events: none;

  transition: all 0.5s ease-in-out;
  opacity: 0;
}

blockquote.seen {
  opacity: 1;
  pointer-events: all;
}

blockquote::before {
  content: "\201C";
  position: absolute;
  top: -30px;
  left: -30px;
  font-family: Georgia, serif;
  font-size: 3.5rem;
}

blockquote::after {
  content: "\201D";
  position: absolute;
  bottom: -30px;
  right: -30px;
  font-family: Georgia, serif;
  font-size: 3.5rem;
}

.hr-container h1 {
  display: flex;
  justify-content: center;
  font-family:cursive;
  margin-top: 3rem;
}
