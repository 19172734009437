.about-us {
  width: 100%;
  overflow: hidden;
}

.workers-intro {
  height: 100vh;
  background-image: url("../../files/bellymassage.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-color: grey;
  background-blend-mode: multiply;
}

.workers-intro img {
  position: absolute;
  bottom: 5%;
  left: 5%;
  border-radius: 10px;
  height: 120px;
  border: 1.5px solid white;
}

.workers-intro h1 {
  position: absolute;
  text-align: center;
  width: 100%;
  filter: drop-shadow(0 0 80px black);
  color: white;
  text-shadow: 0 0 10px black;
  box-sizing: border-box;

  padding-inline: 10%;
  top: 40%;
  font-size: clamp(2rem, 5vw, 3.5rem);
}


.face-position {
    width: 100%;
    display: flex;
justify-content: center;
align-items: center;
filter: drop-shadow(0 0 20px grey);
margin-bottom: 120px;

}

