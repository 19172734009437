.second-image {
  height: 100vh;
  background-image: url("../../files/girlmassage.jpeg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.second-image h3 {
  width: min(650px, 80%);
  margin: auto;

  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 10px;
  padding: 0.5rem 1.25rem;
  font-size: clamp(1.25rem, 3vw, 2.5rem);
  text-shadow: 1px 1px 10px grey;
  text-align: center;
  color: white;
}
