.services {
  width: 100%;
}

.services-intro {
  height: 100vh;
  background-image: linear-gradient(
    155deg,
    var(--primary-colour),
    rgb(50, 50, 50)
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-color: grey;
  background-blend-mode: multiply;
}

.services-intro img {
  position: absolute;
  bottom: 5%;
  left: 5%;
  border-radius: 10px;
  height: 120px;
  border: 1.5px solid white;
}

.services-intro h1 {
  position: absolute;
  text-align: center;
  width: 100%;
  filter: drop-shadow(0 0 80px black);
  color: white;
  text-shadow: 0 0 10px black;
  box-sizing: border-box;

  padding-inline: 10%;
  top: 10%;
  font-size: clamp(2rem, 5vw, 3.5rem);
}

.services-intro ul {
  list-style: none;
  position: absolute;
  top: 28%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  text-align: center;
  font-size: clamp(1.2rem, 3vw, 1.8rem);
}

.serv-link {
  color: white;

  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.serv-link:hover {
  color: rgb(212, 65, 65);
}

.item {
  position: relative;
  padding: 3px;
}

.serv-link .item::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;

  width: 100%;
  height: 3px;
  background-color: rgb(212, 65, 65);
  transform: scaleX(0);
  transform-origin: left;
  font-size: small;


  transition: all 0.3s ease-in-out;
}

.serv-link:hover .item::before {
    content: "Pogledajte...";
  transform: scaleX(1);
right: 0;
  transform-origin: right;
}
